<template>
  <div class="incoming-calls">
    <h1 class="incoming-calls__title">Incoming Calls</h1>
    <IncomingCallsFilters @updateFilters="filters = $event" />
    <IncomingCallsStats :filters="filters" />
    <IncomingCallsChart :filters="filters" />
    <IncomingCallsTable :filters="filters" />
  </div>
</template>

<script>
import IncomingCallsFilters from './sections/incoming_calls_filters'
import IncomingCallsStats from './sections/incoming_calls_stats'
import IncomingCallsChart from './sections/incoming_calls_chart'
import IncomingCallsTable from './sections/incoming_calls_table'

export default {
  components: {
    IncomingCallsFilters,
    IncomingCallsStats,
    IncomingCallsChart,
    IncomingCallsTable
  },
  data() {
    return {
      filters: {
        query: '',
        callStatuses: [''],
        fromDate: '',
        toDate: ''
      }
    }
  }
}
</script>
