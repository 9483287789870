<template>
  <div class="incoming-calls-show">
    <a href="/call_tracking/incoming_calls"
      class="incoming-calls-show__back btn btn-link">
      <i class="fa-regular fa-chevron-left" />
      <span class="pl-2">Incoming Calls</span>
    </a>
    <h1 class="incoming-calls-show__title">
      <span>
        {{ incomingCallDetails.displayName }}
      </span>
      <a
        :href="`/call_tracking/phone_numbers/${incomingCallDetails.phoneObfuscatedId}/edit`"
        id="config-number-link">
        <i class="fa-regular fa-gear" />
      </a>
      <b-popover
        placement="top"
        target="config-number-link"
        custom-class="geogrids-popover p-1 text-center"
        triggers="hover">
        Configure this number
      </b-popover>
    </h1>
    <div class="incoming-calls-show__top">
      <div
        class="incoming-calls-show__status"
        :class="`incoming-calls-show__status--${STATUSES_COLORS[incomingCallDetails.callStatus]}`">
        <i :class="STATUSES_ICONS[incomingCallDetails.callStatus]" />
        <span class="pl-1">
          {{ humanize(incomingCallDetails.callStatus) }}
        </span>
      </div>
      <div class="incoming-calls-show__received">
        {{ incomingCallDetails.received }}
      </div>
    </div>
    <hr class="incoming-calls-show__separator">
    <div class="incoming-calls-show__info">
      <div class="incoming-calls-show__card">
        <i class="incoming-calls-show__tooltip fa-regular fa-circle-info"
          id="incoming-calls-caller" />
        <b-popover
          placement="top"
          target="incoming-calls-caller"
          custom-class="geogrids-popover p-1 text-center"
          triggers="hover">
          The number initiating the call
        </b-popover>
        <p>
          Caller
        </p>
        <span class="incoming-calls-show__number">
          {{ incomingCallDetails.from }}
        </span>
      </div>
      <div class="incoming-calls-show__card">
        <i class="incoming-calls-show__tooltip fa-regular fa-circle-info"
          id="incoming-calls-dialed" />
        <b-popover
          placement="top"
          target="incoming-calls-dialed"
          custom-class="geogrids-popover p-1 text-center"
          triggers="hover">
          The call tracking number the Caller dialed
        </b-popover>
        <p>
          Dialed
        </p>
        <span class="incoming-calls-show__number">
          {{ incomingCallDetails.phoneNumber }}
        </span>
      </div>
      <div v-if="incomingCallDetails.forwardedToNumber" class="incoming-calls-show__card">
        <i class="incoming-calls-show__tooltip fa-regular fa-circle-info"
          id="incoming-calls-forwarded-to" />
        <b-popover
          placement="top"
          target="incoming-calls-forwarded-to"
          custom-class="geogrids-popover p-1 text-center"
          triggers="hover">
          The final destination of a redirected call
        </b-popover>
        <p>
          Forwarded to
        </p>
        <span class="incoming-calls-show__number">
          {{ incomingCallDetails.forwardedToNumber }}
        </span>
      </div>
      <div
        v-if="incomingCallDetails.conversationDuration > 0"
        class="incoming-calls-show__card">
        <p class="incoming-calls-show__card-title">
          Duration
        </p>
        <div class="incoming-calls-show__duration-line">
          <i class="fa-solid fa-hourglass-empty" />
          <span>
            Ring duration
          </span>
          <p>
            {{ incomingCallDetails.ringDuration }} sec.
          </p>
        </div>
        <div class="incoming-calls-show__duration-line">
          <i class="fa-solid fa-phone" />
          <span>
            Conversation
          </span>
          <p>
            {{ incomingCallDetails.conversationDuration }} sec.
          </p>
        </div>
      </div>
    </div>
    <div v-if="incomingCallDetails.recordingUrl" class="incoming-calls-show__recording">
      <p>{{ recordingText }}</p>
      <div v-if="incomingCallDetails.transcription" class="incoming-calls-show__transcription">
        <p>Transcription</p>
        <span>{{ incomingCallDetails.transcription }}</span>
      </div>
      <audio :src="recordingSrc" controls />
    </div>
    <div class="incoming-calls-show__no-recording" v-else>
      No call recording
    </div>
    <div class="incoming-calls-show__timeline">
      <div v-for="({ eventType, occurrenceTime }, index) in incomingCallDetails.callEvents"
        :key="index"
        class="incoming-calls-show__event">
        <time :datetime="occurrenceTime">{{ occurrenceTime }}</time>
        <div class="incoming-calls-show__circle">
          <i class="fa-regular fa-check" />
          <div v-if="index < incomingCallDetails.callEvents.length - 1"
            class="incoming-calls-show__arrow" />
        </div>
        <p>{{ humanize(eventType) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { humanize } from 'common/helpers'
import { STATUSES_ICONS, STATUSES_COLORS } from './helpers'

export default {
  props: {
    incomingCallDetails: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      recordingSrc: null
    }
  },
  methods: {
    humanize,
    fetchMedia() {
      const { recordingUrl, recordingKey } = this.incomingCallDetails
      if (!recordingKey) {
        this.recordingSrc = recordingUrl
        return
      }

      // Axios gets CORS error for whatever reason
      fetch(recordingUrl, {
        headers: {
          Authorization: `Basic ${recordingKey}`
        }
      })
        .then((res) => res.blob())
        .then((audioBlob) => {
          this.recordingSrc = URL.createObjectURL(audioBlob)
        })
    }
  },
  created() {
    this.STATUSES_ICONS = STATUSES_ICONS
    this.STATUSES_COLORS = STATUSES_COLORS
  },
  mounted() {
    this.fetchMedia()
  },
  computed: {
    recordingText() {
      return this.incomingCallDetails.recordingType === 'call' ?
        'Call recording' :
        'Voicemail'
    }
  }
}
</script>
