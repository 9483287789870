<template>
  <div class="phone-numbers">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <a
        href="/call_tracking/phone_numbers/new"
        class="btn btn-sm ml-auto">
        <i class="fa-solid fa-plus" />
        <span class="pl-2">Phone Number</span>
      </a>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <InputSearch v-model.trim="search" custom-classes="form-field-md" />
      <CustomSelect
        class="phone-numbers__select"
        v-model="phoneStatuses"
        :multiple="true"
        :no-tags="true"
        :values="phoneStatusesOptions"
        :allow-empty="true"
        placeholder="All statuses">
        <template #selection="{ props }">
          <span class="multiselect__single" v-if="props.values.length && !props.isOpen">
            <template v-if="props.values.length === 1">
              {{ props.values[0].text }}
            </template>
            <template v-else>
              {{ props.values.length }} statuses selected
            </template>
          </span>
        </template>
      </CustomSelect>
      <button
        class="btn btn-link btn--rem gap-3 ml-auto"
        :class="{ 'text-muted': !anyFiltersSelected }"
        :disabled="!anyFiltersSelected"
        @click="clearFilters">
        <i class="far fa-eraser" />
        <span>Clear filters</span>
      </button>
    </div>

    <VueSlimTable
      ref="table"
      :source="getPhoneNumbers"
      :columns="columns"
      class="responsive-table borderless-table m-0 phone-numbers__table">
      <template #row="{ row }">
        <tr>
          <td>
            {{ row.name }}
          </td>
          <td>
            {{ row.number }}
          </td>
          <td class="text-capitalize">
            {{ row.provider }}
          </td>
          <td class="text-capitalize">
            {{ row.status.split('_').join(' ') }}
          </td>
          <td class="text-end">
            <div class="phone-numbers__actions">
              <a
                class="btn btn-link"
                :href="`/call_tracking/phone_numbers/${row.obfuscatedId}/edit`"
                :id="`configure-phone-number-${row.obfuscatedId}`">
                <i class="far fa-gear" />
              </a>
              <b-popover
                placement='left'
                :target="`configure-phone-number-${row.obfuscatedId}`"
                custom-class='geogrids-popover p-1'
                triggers='hover'>
                Configure phone number
              </b-popover>
            </div>
          </td>
        </tr>
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<!-- TODO: Enable Delete button when it will be ready on backend -->
<!--
<button
  :id="`delete-number-${row.id}`"
  class="btn btn-link btn-danger"
  @click="deletePhoneNumber(row)">
  <i class='far fa-trash' />
</button>
<b-popover
  placement="left"
  :target="`delete-number-${row.id}`"
  custom-class="geogrids-popover p-1"
  triggers="hover">
  <span class="text-danger">Delete phone number</span>
</b-popover>
-->

<script>
import axiosTransform from 'common/axios'
import snakecaseKeys from 'snakecase-keys'

import Pagination from 'vue_widgets/components/pagination'
import InputSearch from 'vue_widgets/components/input_search_ui'
import CustomSelect from 'vue_widgets/components/custom_select'

import { capitalize } from 'common/helpers'

export default {
  components: {
    Pagination,
    InputSearch,
    CustomSelect
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalCount: 0,
      search: '',
      phoneStatuses: [],
      searchTimeout: null
    }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Friendly Name' },
      { key: 'number', title: 'Number' },
      { key: 'provider', title: 'Provider' },
      { key: 'status', title: 'Status' },
      { key: 'actions', title: 'Action' }
    ]

    this.phoneStatusesOptions = ['active', 'inactive', 'not_configured', 'unavailable']
      .map((status) => ({
        value: status,
        text: status
          .split('_')
          .map(capitalize)
          .join(' ')
      }))
  },
  methods: {
    getPhoneNumbers() {
      return axios.get(
        '/call_tracking/ajax/phone_numbers',
        {
          params: {
            ...this.filters,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(snakecaseKeys(json), { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }
      ).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        return res.data
      })
    },
    deletePhoneNumber(/* { id } */) {
      // todo: delete phone number if we'll need it later
      // console.log(id)
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table.reload()
    },
    clearFilters() {
      this.search = ''
      this.phoneStatuses = []
    }
  },
  computed: {
    filters() {
      return {
        search: this.search,
        byStatuses: this.phoneStatuses
      }
    },
    anyFiltersSelected() {
      return Object.keys(this.filters)
        .find((filterName) => this.filters[filterName].length > 0)
    }
  },
  watch: {
    filters: {
      handler() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.updatePageNumber(1)
        }, 500)
      },
      deep: true
    }
  }
}
</script>
