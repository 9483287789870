<template>
  <div class="incoming-calls-filters">
    <div v-for="(field, fieldName) in fields"
      :key="fieldName"
      class="incoming-calls-filters__field">
      <component
        :is="field.component"
        v-model="field.value"
        v-bind="field.options"
        :values="field.values" />
    </div>
  </div>
</template>

<script>
import InputSearch from 'vue_widgets/components/input_search_ui'
import CustomSelect from 'vue_widgets/components/custom_select'
import Datepicker from 'vue_widgets/components/datepicker'

import { daysAgo, dateWithoutTime } from 'common/helpers'

export default {
  components: {
    InputSearch,
    CustomSelect,
    Datepicker
  },
  data() {
    return {
      fields: {
        query: {
          component: 'InputSearch',
          value: ''
        },
        callStatuses: {
          component: 'CustomSelect',
          value: ['answered', 'voicemail', 'missed'],
          values: [
            { value: 'answered', text: 'Answered' },
            { value: 'voicemail', text: 'Voicemail' },
            { value: 'missed', text: 'Missed' },
            { value: 'spam', text: 'Spam' }
          ],
          options: {
            allowEmpty: true,
            multiple: true,
            noTags: true
          }
        },
        dateRange: {
          component: Datepicker,
          value: [daysAgo(31), dateWithoutTime(new Date())],
          options: {
            mode: 'range',
            showMonths: 2,
            dateFormat: 'Y/m/d',
            minDate: dateWithoutTime(new Date('1/1/2025')),
            maxDate: dateWithoutTime(new Date())
          }
        }
      }
    }
  },
  computed: {
    formattedFilters() {
      const mappedValues = {}
      Object.keys(this.fields).forEach((filterName) => {
        mappedValues[filterName] = this.fields[filterName].value
      })
      const { callStatuses, dateRange, query } = mappedValues
      const [fromDate, toDate] = (dateRange ?? [])
      return {
        callStatuses,
        query,
        fromDate,
        toDate
      }
    }
  },
  watch: {
    formattedFilters: {
      handler() {
        this.$emit('updateFilters', this.formattedFilters)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
