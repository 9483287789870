<template>
  <form class="phone-numbers-edit" @submit.prevent="saveConfig">
    <a class="phone-numbers-edit__back btn btn-link"
      href="/call_tracking/phone_numbers">
      <i class="fa-regular fa-chevron-left" />
      <span>
        Phone Numbers
      </span>
    </a>
    <div class="phone-numbers-edit__line">
      <h1 class="phone-numbers-edit__title">{{ phoneNumberDetails.name }}</h1>
      <span class="phone-numbers-edit__number">{{ phoneNumberDetails.number }}</span>
    </div>
    <div class="phone-numbers-edit__line">
      <CheckboxToggle
        v-model="editablePhoneNumberConfig.callFlowActive"
        class="phone-numbers-edit__field">
        <p>
          Active
        </p>
        <span>
          Set your phone numbers to inactive to have them drop any incoming calls immediately.
        </span>
      </CheckboxToggle>
    </div>
    <div class="phone-numbers-edit__line">
      <CheckboxToggle
        v-model="editablePhoneNumberConfig.sendNotification"
        name="send_notification"
        class="phone-numbers-edit__field">
        <p>
          Notification
        </p>
        <span>
          Send notification if the call was directed to voicemail.
        </span>
      </CheckboxToggle>
    </div>
    <div class="phone-numbers-edit__line">
      <CustomInput
        v-model="editablePhoneNumberConfig.friendlyName"
        name="name"
        label="Name"
        :placeholder="`Default: ${phoneNumberDetails.number}`" />
      <span class="phone-numbers-edit__info">
        You can assign unique names to phone numbers to remind you what they are used for.
      </span>
    </div>
    <div class="phone-numbers-edit__group">
      <div class="phone-numbers-edit__group-description">
        <h2>Call Forwarding Settings</h2>
        <span>Call flows determine what happens when a number is called.</span>
      </div>
      <div class="phone-numbers-edit__fieldset">
        <div class="phone-numbers-edit__line">
          <div class="phone-numbers-edit__field-description">
            <p>Caller ID</p>
            <span>This is the number that the call will show as coming from when connected to a recipient.</span>
          </div>
        </div>
        <div class="phone-numbers-edit__line">
          <RadioGroup
            v-model="editablePhoneNumberConfig.callerIdMode"
            :options="callerIdModeOptions"
            name="caller_id_mode"
            class="phone-numbers-edit__radio-group" />
        </div>
        <div class="phone-numbers-edit__line">
          <PhoneInput
            v-model="editablePhoneNumberConfig.ringThroughNumber"
            name="number_to_dial"
            label="Number To Dial"
            :error-text="errorText('ringThroughNumber')"
            :required="true"
            @input="cleanError('ringThroughNumber')"
            @updateErrors="updateErrors" />
          <span class="phone-numbers-edit__info">Enter the answering recipient’s phone number in the format +1 509 555 01 00.</span>
        </div>
        <div class="phone-numbers-edit__line">
          <CustomSelect
            v-model="editablePhoneNumberConfig.legLength"
            name="leg_length"
            :values="legLengthOptions"
            label="Leg Length" />
          <span class="phone-numbers-edit__info">Choose how long you want a phone call leg to last before going to voicemail or the next number.</span>
        </div>
        <div class="phone-numbers-edit__line">
          <CustomCheckbox
            v-model="editablePhoneNumberConfig.challengeEnabled"
            name="challenge_enabled"
            class="phone-numbers-edit__field">
            <p>
              Enable Challenge
            </p>
            <span>
              Requires a recipient to press a button to be connected to a caller.
            </span>
          </CustomCheckbox>
        </div>
        <div class="phone-numbers-edit__line">
          <CustomCheckbox
            v-model="editablePhoneNumberConfig.roboblockEnabled"
            name="roboblock_enabled"
            class="phone-numbers-edit__field">
            <p>
              Enable RoboBlock
            </p>
            <span>
              Require the caller to press a button before the call is connected to a recipient.
            </span>
          </CustomCheckbox>
        </div>
        <div class="phone-numbers-edit__line">
          <CustomCheckbox
            v-model="editablePhoneNumberConfig.blocklistEnabled"
            name="blocklist_enabled"
            class="phone-numbers-edit__field">
            <p>
              Enable Blocklist
            </p>
            <span>
              Callers from blocklisted numbers will not be connected to a recipient.
            </span>
            <a href="/call_tracking/blocklist/edit" target="_blank">
              <i class="fa-regular fa-arrow-up-right-from-square" />
              Blocklisted numbers
            </a>
          </CustomCheckbox>
        </div>
      </div>
    </div>
    <div class="phone-numbers-edit__group">
      <div class="phone-numbers-edit__group-description">
        <h2>Business Hours</h2>
        <span>
          Business hours can be set on a daily basis.
          When a call comes in outside of business hours it goes directly to voicemail.
          By default, the number is available 24/7.
        </span>
      </div>
      <div class="phone-numbers-edit__fieldset">
        <div class="phone-numbers-edit__line">
          <CustomSelect
            v-model="editablePhoneNumberConfig.timeZone"
            name="time_zone"
            :values="timeZones"
            label="Time zone" />
          <span class="phone-numbers-edit__info">Set the Time Zone of the business operating hours this phone number is assigned to.</span>
        </div>
        <div class="phone-numbers-edit__line">
          <WorkdaySchedule
            v-model="editablePhoneNumberConfig.schedule"
            name="schedule" />
        </div>
      </div>
    </div>
    <div class="phone-numbers-edit__group">
      <div class="phone-numbers-edit__group-description">
        <h2>Voicemail</h2>
        <span>
          Your voicemail option determines what a caller hears when the phone isn't picked up or the call is made outside of operating hours.
        </span>
      </div>
      <div class="phone-numbers-edit__fieldset">
        <div class="phone-numbers-edit__line">
          <CustomCheckbox
            v-model="editablePhoneNumberConfig.directToVm"
            name="direct_to_vm"
            class="phone-numbers-edit__field">
            <p>
              Direct To VM
            </p>
            <span>
              Send calls directly to voicemail without ringing a recipient
            </span>
          </CustomCheckbox>
        </div>
        <div class="phone-numbers-edit__line">
          <div class="phone-numbers-edit__field-description">
            <p>VM Greeting</p>
          </div>
          <CompactTabs
            class="phone-numbers-edit__tabs"
            :tab-list="tabs.vmGreeting"
            :active-tab="editablePhoneNumberConfig.voiceMailMessageType"
            @setActiveTab="editablePhoneNumberConfig.voiceMailMessageType = $event">
            <template #text>
              <span class="phone-numbers-edit__info">
                The text you input here will be spoken by a text to speech robot when the caller reaches your voicemail.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.voiceMailText"
                placeholder="Enter voicemail greeting text"
                name="voice_mail_text"
                :required="editablePhoneNumberConfig.voiceMailMessageType === 'text'" />
            </template>
            <template #mp3>
              <span class="phone-numbers-edit__info">
                Enter an MP3 (https://api.twilio.com/cowbell.mp3) URL to be played when the caller reaches your voicemail.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.voiceMailUrl"
                placeholder="Enter URL of MP3 file"
                name="voice_mail_url"
                :required="editablePhoneNumberConfig.voiceMailMessageType === 'mp3'" />
            </template>
          </CompactTabs>
        </div>
        <div class="phone-numbers-edit__line">
          <CustomCheckbox
            v-model="editablePhoneNumberConfig.vmTranscription"
            name="send_notification"
            class="phone-numbers-edit__field">
            <p>
              VM Transcription
            </p>
            <span>
              Signalwire offers programmatic transcriptions of voicemails that can email or text to your clients.
              Signalwire charges an extra fee so please check the latest Signalwire prices.
            </span>
          </CustomCheckbox>
        </div>
      </div>
    </div>
    <div class="phone-numbers-edit__group">
      <div class="phone-numbers-edit__group-description">
        <h2>Whisper</h2>
        <span>
          A whisper is played before every phone call is connected.
          You can let a contractor or partner know where he's receiving calls from by stating it here.
        </span>
      </div>
      <div class="phone-numbers-edit__fieldset">
        <div class="phone-numbers-edit__line">
          <CheckboxToggle
            v-model="enableWhisper"
            name="direct_to_vm"
            class="phone-numbers-edit__field">
            <p>
              Use Whisper
            </p>
          </CheckboxToggle>
        </div>
        <div class="phone-numbers-edit__line">
          <CompactTabs
            v-if="editablePhoneNumberConfig.whisperType !== NO_WHISPER"
            :tab-list="tabs.whisperType"
            :active-tab="editablePhoneNumberConfig.whisperType"
            class="phone-numbers-edit__tabs"
            @setActiveTab="editablePhoneNumberConfig.whisperType = $event">
            <template #whisper_text_to_speech>
              <span class="phone-numbers-edit__info">
                This text will be announced by a robot before each call is connected.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.whisperText"
                placeholder="Enter text for whisper"
                name="whisper_text"
                :required="editablePhoneNumberConfig.whisperType === 'whisper_text_to_speech'" />
            </template>
            <template #whisper_mp3_url>
              <span class="phone-numbers-edit__info">
                Enter an MP3 (https://api.twilio.com/cowbell.mp3) URL to be played before each call is connected.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.whisperUrl"
                placeholder="Enter URL of MP3 file"
                name="whisper_url"
                :required="editablePhoneNumberConfig.whisperType === 'whisper_mp3_url'" />
            </template>
          </CompactTabs>
        </div>
      </div>
    </div>
    <div class="phone-numbers-edit__group">
      <div class="phone-numbers-edit__group-description">
        <h2>Recording</h2>
        <span>
          Recorded calls allow you to listen to conversations after they've taken place.
          Recording calls without the consent of the caller may not be legal.
          Please check local laws before recording callers.
        </span>
      </div>
      <div class="phone-numbers-edit__fieldset">
        <div class="phone-numbers-edit__line">
          <CheckboxToggle
            v-model="editablePhoneNumberConfig.recordingEnabled"
            name="recording_enabled"
            class="phone-numbers-edit__field">
            <p>
              Enable Recording
            </p>
          </CheckboxToggle>
        </div>
        <div v-if="editablePhoneNumberConfig.recordingEnabled"
          class="phone-numbers-edit__line">
          <div
            class="phone-numbers-edit__field-description"
            :class="{ disabled: !editablePhoneNumberConfig.recordingEnabled }">
            <p>Recording Warning </p>
            <span>Choose what a caller hears when recording is enabled for this number. </span>
          </div>
        </div>
        <div class="phone-numbers-edit__line">
          <CompactTabs
            v-if="editablePhoneNumberConfig.recordingEnabled"
            :tab-list="tabs.recording"
            :active-tab="editablePhoneNumberConfig.recordingWarningType"
            class="phone-numbers-edit__tabs"
            @setActiveTab="editablePhoneNumberConfig.recordingWarningType = $event">
            <template #text>
              <span class="phone-numbers-edit__info">
                This text will be announced by a robot when callers connect.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.recordingWarningText"
                placeholder="Enter text for recording warning"
                name="recording_warning_text"
                :required="editablePhoneNumberConfig.recordingWarningType === 'text'" />
            </template>
            <template #mp3>
              <span class="phone-numbers-edit__info">
                Enter an MP3 (https://api.twilio.com/cowbell.mp3) URL to be played as recording notice when callers connect.
              </span>
              <CustomInput
                v-model="editablePhoneNumberConfig.recordingWarningUrl"
                placeholder="Enter URL of MP3 file"
                name="recording_warning_url"
                :required="editablePhoneNumberConfig.recordingWarningType === 'mp3'" />
            </template>
          </CompactTabs>
        </div>
      </div>
    </div>
    <div class="phone-numbers-edit__bottom">
      <a class="btn" href="/call_tracking/phone_numbers">
        Cancel
      </a>
      <button class="btn btn-primary">
        Update
      </button>
    </div>
  </form>
</template>

<script>
import CheckboxToggle from 'vue_widgets/components/checkbox_toggle'
import RadioGroup from 'vue_widgets/components/radio_group'
import CustomInput from 'vue_widgets/components/custom_input'
import PhoneInput from 'vue_widgets/components/phone_input'
import CustomSelect from 'vue_widgets/components/custom_select'
import CustomCheckbox from 'vue_widgets/components/checkbox'
import WorkdaySchedule from 'vue_widgets/components/workday_schedule'
import CompactTabs from 'vue_widgets/components/compact_tabs'

import axiosTransform from 'common/axios'
import validationMixin from 'vue_widgets/mixins/validation_mixin'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

const NO_WHISPER = 'no_whisper'
const DEFAULT_WHISPER = 'whisper_text_to_speech'

export default {
  mixins: [validationMixin],
  components: {
    CheckboxToggle,
    CustomCheckbox,
    RadioGroup,
    CustomInput,
    PhoneInput,
    CustomSelect,
    WorkdaySchedule,
    CompactTabs
  },
  props: {
    phoneNumberDetails: {
      type: Object,
      required: true
    },
    timeZones: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      editablePhoneNumberConfig: {
        callFlowActive: false,
        friendlyName: '',
        callerIdMode: '',
        ringThroughNumber: '', // TODO: ask what is this,
        legLength: 30,
        challengeEnabled: false,
        roboblockEnabled: false,
        blocklistEnabled: false,
        timeZone: '',
        schedule: {},
        voiceMailMessageType: '',
        voiceMailText: '',
        voiceMailUrl: '',
        vmTranscription: false,
        sendNotification: false,
        whisperType: '',
        whisperText: '',
        whisperUrl: '',
        recordingEnabled: false,
        recordingWarningType: '',
        recordingWarningText: '',
        recordingWarningUrl: '',
        directToVm: false
      },
      enableWhisper: false,
      previousWhisperSetting: null
    }
  },
  created() {
    this.callerIdModeOptions = {
      callerAsId: {
        value: 'use_caller_as_id',
        label: 'Use Caller as ID'
      },
      callTrackingNumberAsId: {
        value: 'use_call_tracking_number_as_id',
        label: 'Use Call Tracking Number as ID'
      }
    }

    this.legLengthOptions = [10, 20, 30, 60, 90, 180].map((legLength) => ({
      text: `${legLength} Seconds`,
      value: legLength
    }))

    const commonTabs = {
      text: {
        text: 'Text to Speech',
        icon: 'fa-regular fa-message-text'
      },
      mp3: {
        text: 'MP3 URL',
        icon: 'fa-regular fa-waveform-lines'
      }
    }

    this.tabs = {
      vmGreeting: { ...commonTabs },
      whisperType: {
        whisper_text_to_speech: {
          text: 'Text to Speech',
          icon: 'fa-regular fa-message-text'
        },
        whisper_mp3_url: {
          text: 'MP3 URL',
          icon: 'fa-regular fa-waveform-lines'
        }
      },
      recording: { ...commonTabs }
    }

    this.NO_WHISPER = NO_WHISPER

    this.setInitialValues()
  },
  methods: {
    scrollToErroneousField() {
      const el = document.querySelector('.has-errors')

      el?.scrollIntoView({
        behavior: 'smooth'
      })
    },
    saveConfig() {
      return axios.put(
        `/call_tracking/ajax/phone_numbers/${this.phoneNumberDetails.obfuscatedId}`,
        {
          phoneNumber: this.editablePhoneNumberConfig
        },
        {
          ...axiosTransform
        }
      )
        .then(() => {
          $(document).one('turbolinks:load', () => {
            toastr.success('Call flow updated successfully')
          })
          Turbolinks.visit('/call_tracking/phone_numbers')
        })
        .catch((err) => {
          const errorText = err.response?.data?.text ?? DEFAULT_ERROR_MESSAGE
          toastr.error(errorText)
          this.$nextTick(this.scrollToErroneousField)
        })
    },
    setInitialValues() {
      this.enableWhisper = this.phoneNumberDetails.whisperType !== this.NO_WHISPER
      this.previousWhisperSetting = this.phoneNumberDetails.whisperType

      Object.keys(this.editablePhoneNumberConfig).forEach((key) => {
        const initialValue = this.phoneNumberDetails[key]
        if (!initialValue) return
        this.editablePhoneNumberConfig[key] = initialValue
      })
    }
  },
  watch: {
    'editablePhoneNumberConfig.whisperType': {
      handler(newVal, oldVal) {
        if (newVal !== NO_WHISPER) return

        this.previousWhisperSetting = oldVal
      }
    },
    enableWhisper(newVal) {
      if (!newVal) {
        this.editablePhoneNumberConfig.whisperType = NO_WHISPER
        return
      }

      this.editablePhoneNumberConfig.whisperType = this.previousWhisperSetting || DEFAULT_WHISPER
    },
    'editablePhoneNumberConfig.recordingWarningType': {
      handler(newRecordingWarningType) {
        if (newRecordingWarningType !== 'default') return

        this.editablePhoneNumberConfig.recordingWarningType = 'text'
        // TODO: Confirm that we decided to ditch it.
        // I did that because it was removed in new jensen.
        // this.editablePhoneNumberConfig.recordingWarningText = 'This call may be recorded for quality and training purposes'
      },
      immediate: true
    }
  }
}
</script>
