<template>
  <div>
    <div class="st-filter">
      <div class="form-field">
        <label class="m-0">Tenant</label>
        <Multiselect
          v-model="filters.serviceTitanTenant"
          :options="serviceTitanTenants"
          :max-height="540"
          :limit="300"
          :options-limit="300"
          :multiple="false"
          track-by="id"
          label="title"
          @select="onChange"
          @remove="onChange">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
            <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].title }}</span>
          </template>
        </Multiselect>
      </div>

      <div class="form-field">
        <label class="m-0">Business Unit</label>
        <Multiselect
          v-model="filters.serviceTitanBusinessUnit"
          :internal-search="false"
          :options="serviceTitanBusinessUnitOptions"
          :max-height="540"
          :limit="300"
          :options-limit="300"
          :loading="isLoading"
          :multiple="false"
          track-by="id"
          label="name"
          placeholder="Start typing to search"
          @select="onChange"
          @remove="onChange"
          @search-change="getServiceTitanBusinessUnits">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
            <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].title }}</span>
          </template>
        </Multiselect>
      </div>

      <div class="form-field">
        <label class="m-0">Job Type</label>
        <Multiselect
          v-model="filters.serviceTitanJobType"
          :internal-search="false"
          :options="serviceTitanJobTypeOptions"
          :max-height="540"
          :limit="300"
          :options-limit="300"
          :loading="isLoading"
          :multiple="false"
          track-by="id"
          label="name"
          placeholder="Start typing to search"
          @select="onChange"
          @remove="onChange"
          @search-change="getServiceTitanJobTypes">
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
            <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].title }}</span>
          </template>
        </Multiselect>
      </div>

      <div class="form-field">
        <label class="m-0">Customer</label>
        <input type="text" v-model.trim="filters.customer" @input="onInput">
      </div>

      <div class="form-field">
        <label class="m-0">Summary</label>
        <input type="text" v-model.trim="filters.summary" @input="onInput">
      </div>

      <div class="form-field">
        <label class="m-0">Address</label>
        <input type="text" v-model.trim="filters.address" @input="onInput">
      </div>

      <div class="form-field">
        <label class="m-0">Remote completed on</label>
        <DatePicker
          class="w-100"
          mode="range"
          v-model="filters.byPublishingDate"
          @input="onChange" />
      </div>
    </div>
    <div class="st-filter">
      <div class="form-field">
        <CheckboxToggle v-model="filters.readyForCheckin" @input="onChange">
          Ready for checkin
        </CheckboxToggle>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue_widgets/components/datepicker'
import CheckboxToggle from 'vue_widgets/components/checkbox_toggle'

export default {
  components: {
    Multiselect, DatePicker, CheckboxToggle
  },
  props: {
    serviceTitanTenants: { type: Array, default: () => []},
    filterValues: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      filters: {
        serviceTitanTenant: null,
        byPublishingDate: [],
        serviceTitanBusinessUnit: null,
        serviceTitanJobType: null,
        readyForCheckin: false,
        ...this.filterValues
      },
      serviceTitanBusinessUnitOptions: [],
      serviceTitanJobTypeOptions: [],
      isLoading: false,
      timeout: null
    }
  },
  methods: {
    onChange() {
      this.$emit('input', {
        ...this.filters,
        serviceTitanTenant: this.filters.serviceTitanTenant?.id,
        serviceTitanBusinessUnit: this.filters.serviceTitanBusinessUnit?.id,
        serviceTitanJobType: this.filters.serviceTitanJobType?.id
      })
    },
    onInput() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.onChange, 300)
    },
    getServiceTitanBusinessUnits(search) {
      if (search.length < 3) return
      this.isLoading = true
      axios.get('/gb/ajax/service_titan/business_units', { params: { search } })
        .then((res) => {
          this.serviceTitanBusinessUnitOptions = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getServiceTitanJobTypes(search) {
      if (search.length < 3) return
      this.isLoading = true
      axios.get('/gb/ajax/service_titan/job_types', { params: { search } })
        .then((res) => {
          this.serviceTitanJobTypeOptions = res.data
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
